import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from "../components/Main";
import Kasulikud from "../components/Kasulikud";
import Kontakt from "../components/Kontakt";
import Seltsist from "../components/Seltsist";
import Uritused from "../components/Uritused";
import PageNotFound from "../components/PageNotFound";

Vue.use(VueRouter);

let router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Main',
      component: Main,
    },
      /*
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        guest: true
      }
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      meta: {
        requiresAuth: true,
        is_admin : true
      },
    },
     */
    {
      path: '/lingid',
      name: 'Kasulikud lingid',
      component: Kasulikud,
    },
    {
      path: '/kontakt',
      name: 'Kontakt',
      component: Kontakt,
    },
    {
      path: '/seltsist',
      name: 'Seltsist',
      component: Seltsist,
    },
    {
      path: '/uritused',
      name: 'Üritused',
      component: Uritused,
    },
    {
      path:'*',
      name: 'Error',
      component: PageNotFound,
    }
  ]
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt') == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      let user = JSON.parse(localStorage.getItem('user'))
      if(to.matched.some(record => record.meta.is_admin)) {
        if(user.is_admin === 1){
          next()
        }
      }else {
        next()
      }
    }
  } else if(to.matched.some(record => record.meta.guest)) {
    if(localStorage.getItem('jwt') == null){
      next()
    }
  }else {
    next()
  }
});

export default router
